import React, { useState, useEffect } from "react"


const TextStep = ({ step, params, index, length, nextStep, prevStep, form, setForm }) => {
    const amIChecked = (answer) => {
        if (!form[step.title]) {
            return false
        }
        return form[step.title].includes(answer.title)
    }

    const updateCheck = (answer) => {
        if (amIChecked(answer)) {
            unsetAnswer(answer)
        } else {
            setAnswer(answer)
        }
    }

    const setAnswer = (answerIn) => {
        let answers = form[step.title]

        if (answers[0] == "") {
            answers.splice(0, 1)
        }
        answers.push(answerIn.title)

        setForm((prev) => {
            let newForm = {}
            for (let i in prev) {
                newForm[i] = prev[i]
            }
            newForm[step.title] = answers
            return newForm
        })
    }

    const unsetAnswer = (answerOut) => {
        let answers = form[step.title]
        if (answers[0] == "") {
            answers.splice(0, 1)
        }
        for (var key in answers) {
            if (answers[key] === answerOut.title) {
                answers.splice(key, 1);
            }
        }
        let newForm = {}
        for (let i in form) {
            newForm[i] = form[i]
        }

        newForm[step.title] = answers
        setForm(newForm)
    }

    useEffect(() => {
        if (form[step.title] === undefined) {
            setForm({
                ...form,
                [step.title]: [],
            })
        }

    }, [step.title])

    return <React.Fragment>
                {step.answers.map((answer) => {
                    return <div class="form__wrapper" onClick={() => updateCheck(answer)} answer={answer.title}>
                                <label class="form__label pointer">
                                    <span class={amIChecked(answer)?"form__checkerRadio checked":"form__checkerRadio"}></span>
                                    <span class="text_smd">{answer.title}</span>
                                </label>
                            </div>
                })}
            </React.Fragment>
    
    
   
}

export default TextStep