import React, { useEffect, useState } from "react"
import BtnComponent from "../btn-component";
import SliderStep from "./slider-step";
import TextStep from "./text-step";
import { QuestionType } from "./enums";
import axios from "axios";
import { SidePanel } from "./side-panel";
import { phoneClickDataLayer, stepChangeDataLayer } from "../../utils/send-data";

const Step = ({ step, params, index, length, nextStep, prevStep, raionsStep, roomsStep, form, setForm, ignoreFirst }) => {
    const [prices, setPrices] = useState({ min_value: 3000000, max_value: 15000000 })
    const backBtn = (index, ignoreFirst) => {
        switch (index) {
            case 0:
                return ""
            case 1:
                if (ignoreFirst) {
                    return ""
                } else {
                    return <BtnComponent text={"Назад"} analog={true} params={params} clickFunct={(e) => { stepChangeDataLayer(false, index + 1); prevStep(e) }} />
                }
            default:
                return <BtnComponent text={"Назад"} analog={true} params={params} clickFunct={(e) => { stepChangeDataLayer(false, index + 1); prevStep(e) }} />
        }
    }

    useEffect(async () => {
        let response = await axios.get("/tmp_prices/" + domainSettings.city.ID)
        setPrices(response.data.payload)
    }, [])


    return (<div class="pAbs zIndex_1 layout layoutSizeMax content over-wrapper js-poll-quiz contentActive">
        <div class="content__container">
            <SidePanel form={form} setForm={setForm} raionsName={raionsStep} roomsName={roomsStep} celtype={"getForm"} />
            <div class="content__content pRel vue-init vue-completed">
                <div class="content__wrapper heightAll">
                    <div class="content__progressBox">
                        <div class="pRel">
                            <div class="medium text_md progress__text layout pAbs">{index + 1}/{length}</div>
                            <svg viewBox="0 0 32 32" class="progress__container">
                                <circle r="16" cx="16" cy="16" class="progress__circleEmpty"></circle>
                                <circle r="16" cx="16" cy="16" stroke-dasharray={((index + 1) / length) * 100 + " 100"} class="progress__circleFull"></circle>
                            </svg>
                        </div>
                    </div>
                    <div class="content__form">
                        <div class="content__formWrapper">
                            <div class="form__title title__form bold">{step.title}</div>
                            <div class="content__formContainer">
                                <form>
                                    {(() => {
                                        switch (step.questionType) {
                                            case QuestionType.SimpleQuestion:
                                            case QuestionType.RaionsQuestion:
                                            case QuestionType.RoomsQuestion:
                                            case QuestionType.PriceQuestionVariants:
                                            case QuestionType.CompletionQuestion:
                                            case QuestionType.ClassQuestion:
                                            case QuestionType.DistrictQuestion:
                                                return <TextStep step={step} params={params} index={index} length={length} nextStep={nextStep} prevStep={prevStep} form={form} setForm={setForm} />
                                            case QuestionType.PriceQuestionSlider:
                                                return <SliderStep step={step} params={params} index={index} length={length} nextStep={nextStep} prevStep={prevStep} form={form} setForm={setForm} />
                                        }
                                    })()}
                                    <div class="content__buttonBox">
                                        {backBtn(index, ignoreFirst)}
                                        <div class="growOn"></div>
                                        <BtnComponent text={"Дальше"} params={params} clickFunct={(e) => { stepChangeDataLayer(true, index + 1); nextStep(e) }} />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="content__footerMobile">
                        <a href="https://www.novostroy-m.ru"><img src="/images/quiz/novostroy-logo_gray.svg" alt="" height="28px" /></a>
                        <div class="text_xs">
                            Консультируем бесплатно:<a class="color_blue" onClick={(e) => phoneClickDataLayer(domainSettings.organization.phone)} href={"tel:" + domainSettings.organization.phone}>{domainSettings.organization.phone}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}





export default Step