import React, { useEffect, useState } from "react"
import BtnComponent from "../btn-component"
import CityTitle from "../city-title"
import parse from 'html-react-parser'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { library } from '@fortawesome/fontawesome-svg-core'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import { MainCarousel } from "./components/main-carousel"
import { phoneClickDataLayer, startQuizDataLayer } from "../../utils/send-data"

library.add(
    faPhone
)
  
const MainScreen = ({ params, nextStep }) => {
    const [shPolicy, setShPolicy] = useState(true)

    useEffect(()=>{
        try {
            //domainSettings.organization.phone = domainSettings.organization.phone.replace('-', "").replace('-', "").replace(' ', "").replace(' ', "")
        } catch (e) {

        }
    },[])
    return <React.Fragment>
        <div class="pAbs zIndex_1 layout layoutSizeMax over-wrapper js-start-quiz">
        <div class="pAbs zIndex_1 layout layoutSizeMax bgrDark_1">
            <div class="header__container pRel">
                <div class="header__pattern pAbs layoutSize layout zIndex_1"></div>
                <div class="header__layoutPromo pAbs zIndex_3">
                    <MainCarousel nextStep={nextStep} />
                </div>
                <div class="header__headerBox pRel zIndex_2">
                    <div class="header__headerWrapper with-call-me">
                        <a class="header__headerPhone text_slg clrWhite" onClick={(e)=> phoneClickDataLayer(domainSettings.organization.phone)} href={"tel:"+domainSettings.organization.phone}>{domainSettings.organization.phone}</a>
                        <div class="opacity_5 clrWhite">Бесплатная консультация</div>
                        <a class="header__headerCallMe">
                            <FontAwesomeIcon icon={faPhone} size="4x" />
                        </a>
                    </div>
                </div>
                <div class="header__descBox pRel zIndex_2">
                    <div class="clrWhite ">
                        <div class="title__main bold header__descIndent">
                            {domainSettings.title === undefined || domainSettings.title == "" ? parse("Подбор квартир в новостройках<br /> по индивидуальным параметрам") : parse(domainSettings.title)}
                        </div>
                        <div class="opacity_5 text_smd header__descIndent">
                            {domainSettings.domain.sub_title_items === undefined || domainSettings.domain.sub_title_items == '' ? parse("Ответьте всего на несколько вопросов и получите список<br /> предложений от проверенных застройщиков") :parse(domainSettings.domain.sub_title_items)}
                        </div>
                        <a class="header__descLink" href="#" onClick={(e) => {startQuizDataLayer(); nextStep(e)}}>Начать подбор</a>
                    </div>
                </div>
                <div class="header__utpBox pRel zIndex_2 clrWhite">
                    <div class="header__utpWrapper">
                        Найдем <span class="header__utpBadge">идеальную</span> квартиру  <br />за 2 минуты
                    </div>
                    <div class="header__utpWrapper">
                        <span class="header__utpBadge">12516</span> проверенных <br />новостроек
                    </div>
                    <div class="header__utpWrapper"><span class="header__utpBadge">Надежные</span> <br />застройщики</div>
                </div>
                <div class=""></div>
            </div>
        </div>
    </div>
    </React.Fragment>
}

export default MainScreen