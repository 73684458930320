import { library } from '@fortawesome/fontawesome-svg-core'
import { faClose } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from 'react';
import { SendData, sendLeadToDataLayer } from '../../utils/send-data';
import { PhoneInput } from '../phone-input/phone-input';

library.add(
    faClose
)

export const Modal = ({close, form, setForm, raionsName, roomsName, sdachaName, celtype}) => {
    const [blocked, setBlocked] = useState(false)

    const sendHandler = (e) => {
        e.preventDefault()
        if (blocked) {
            return
        }
        
        let code = parseInt(form.phone.substring(4,7))
        if (form.phone.length !== 18 || (code >= 600 && code <= 899)) {
            document.querySelector('input[name="phone"]').parentElement.classList.add('has-error') 
            let info = sendLeadToDataLayer(form, true) //send error to data layer
            dataLayer.push(info)
            return
        }

        if (domainSettings.domain.validate_phone ) {
            setBlocked(true)
            fetch("/lead/validator/"+form.phone)
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    console.log(data);
                    if (data.valid) {
                        SendData(form, setForm, ()=>{close()}, celtype)
                    } else {
                        SendData(form, setForm, ()=>{close()}, celtype, true)
                        close()
                    }
                });
        } else {
            SendData(form, setForm, ()=>{close()}, celtype)
        }
    }

    const changeInputHandler = (e) => {
        setForm({
            ...form,
            [e.currentTarget.name]:e.currentTarget.value
        })
        console.log(form)
    } 
    if (celtype === "getCallback") {
        return <div class="modal js-js-quiz-callback-popup fade in" tabindex="-1" role="dialog" data-url="quiz/api/quiz-callback" style={{display: "block", paddingRight: "0px"}}>
                    <div class="modal-dialog modal-xs" role="document">
                        <div class="modal-content">
                            <div class="js-modal-body">
                                <div class="modal-header">
                                    <h2 class="modal-title js-title-pop-form">Заказать обратный звонок</h2>
                                    <button class="modal-close" data-dismiss="modal" aria-label="Close" onClick={close}><FontAwesomeIcon icon={faClose} /></button>
                                </div>
                                <div class="modal-body">
                                    <form id="quiz-callback-form" class="js-quiz-callback">
                                        <div class="form-group field-callbackform-fio">
                                            <input type="fio" id="callbackform-fio" class="" onInput={changeInputHandler} name="name" value={form.name} placeholder="Как вас зовут?" />
                                            <div class="help-block"></div>
                                        </div>
                                        <div class="form-group field-callbackform-phone required">  
                                            <PhoneInput stateChange={(val)=>{setForm({...form, phone:val})}}/>
                                            <div class="help-block">Необходимо заполнить поле «Телефон» в формате +7 (xxx) xxx-xx-xx </div>
                                        </div>
                                        <div class="js-error"></div>
                                        <div class="modal-agreement">Нажимая на кнопку, Вы даете согласие на обработку своих <a href="/soglashenie" target="_blank">персональных данных</a></div>
                                        <button class="modal-button-submit" onClick={sendHandler}>Отправить</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    }
    return (
        <div data-backdrop="static" class="modal fade layoutSize layout pAbs modal__container in" fragment="c9db63c1b">
            <div class="modal__wrapper modal-dialog">
                <div>
                    <div class="text_slg bold modal__title">Оставьте свои контакты, чтобы увидеть все результаты</div>
                </div>
                <div>
                    <div>
                        <form novalidate="novalidate" class="modal__formBox">
                            <div class="modal__formContainer">
                                <div class="modal__formWrapper">
                                    <input type="text" onInput={changeInputHandler} name="name" value={form.name} placeholder="Как вас зовут?" class="modal__form text_md" aria-required="false" aria-invalid="false" />
                                </div>
                                <div class="modal__formWrapper required">
                                    <PhoneInput stateChange={(val)=>{setForm({...form, phone:val})}}/>
                                    <div class="modal__formError text_xs">Необходимо заполнить поле «Телефон» в формате +7 (xxx) xxx-xx-xx</div>
                                </div>
                                {/*<div class="modal__formWrapper">
                                    <input type="email" name="email" placeholder="Электронная почта*" class="modal__form text_md" aria-required="false" aria-invalid="false" />
                                </div>*/}
                            </div>
                            <div class="opacity_5 text_xs modal__formAgree"> Нажимая на кнопку, Вы даете согласие на обработку своих <a href="/soglashenie" target="_blank">персональных данных</a>.</div>
                            <button type="submit" class="button_def button__size button__type_1 text_md medium" onClick={sendHandler}>Отправить</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}