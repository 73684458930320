import { useEffect, useState } from "react"
import { domainSettingsForTest } from "../../utils"
import FinishSteps from "../finish-steps"
import MainScreen from "../main-screen"
import Step from "../steps"
import Cookies from 'js-cookie'

const Router = () => {
    const [step, setStep] = useState(null)
    const [params, setParams] = useState({})
    const [raionsName, setRaionsName] = useState("")
    const [roomsName, setRoomsName] = useState("")
    const [sdachaName, setSdachaName] = useState("")
    const [raionsPrice, setRaionsPrice] = useState("")

    const [ignoreFirstStep, setIgnore] = useState(false)

    const [form, setForm] = useState({})
    const nextStep = (event) => {
        try {
            event.preventDefault()
        } catch (e) { }
        if (step === null) {
            setStep(0)
        } else {
            setStep(step + 1)
        }
    }
    const prevStep = (event) => {
        try {
            event.preventDefault()
        } catch (e) { }
        if (step - 1 < 0) {
            setStep(0)
        } else {
            setStep(step - 1)
        }
    }

    useEffect(() => {
        if (domainSettings.domain.yandex != "") {
            try {
                setTimeout(() => {
                    ym(parseInt(domainSettings.domain.yandex), 'getClientID', function (clientID) {
                        domainSettings.domain.clientID = clientID
                    })
                }, 3000)
            } catch (e) { }
        }
        setParams(domainSettings.domain)
    }, [])

    const isMobile = () => {
        let check = false;
        if ((/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent))) {
            check = true
        }
        return check;
    }

    const getBackground = () => {
        if (isMobile()) {
            return domainSettings.domain.background_mobile
        }
        return domainSettings.domain.background
    }

    return <div>
        <div className="container_main" style={{ background: params.background != "" ? `url("/file-store/${getBackground() != "" ? getBackground() : params.background}") center / cover no-repeat` : `` }}>
            {(() => {
                switch (true) {
                    case step === null:
                        return <MainScreen params={params} nextStep={nextStep} />
                    case params.steps.length <= step:
                        return <FinishSteps form={form} setForm={setForm} params={params} />;
                    default:
                        return <Step step={params.steps[step]} params={params} index={step} length={params.steps.length} nextStep={nextStep} prevStep={prevStep} form={form} setForm={setForm} />;
                }
            })()}
        </div>
        {/*<div className="about_company">
            <div className="ac__content">
                <div className="ac__left">
                    {domainSettings.organization.name}, <br />
                    {domainSettings.organization.adress}
                </div>
                <div className="ac__right">
                    <a href={"tel:"+domainSettings.organization.phone}>{domainSettings.organization.phone}</a>
                </div>
            </div>
        </div>*/}
    </div>

}

export default Router