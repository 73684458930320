import { library } from '@fortawesome/fontawesome-svg-core'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from 'react';
import { phoneClickDataLayer } from '../../utils/send-data';
import { Modal } from "../modal/modal";

library.add(
    faPhone
)


export const SidePanel = ({form, setForm, raionsPrice, raionsStep, roomsStep, sdachaName}) => {
    const [showModal, setShowModal] = useState(false)
    const [date, setDate] = useState()
    const months = ["января", "февраля", "марта", "апреля", "мая", "июня", "июля", "августа", "сентября", "октября", "ноября", "декабря"]

    useEffect(()=>{
        var now = new Date();
        setDate(now.getDate() + " " + months[now.getMonth()])
    },[])
    return ( <React.Fragment>
        <div class="content__header">
            <div class="content__headerLinks">
                <div class="clrWhite opacity_5 indentB_md">
                    <div class="clrWhite text_smd"></div>
                </div>
                <div class="content__headerLinkBox">
                    <div className="content__headerLink indentB_md">
                        <div class="clrWhite text_smd">Уникальный сервис по экскурсиям на объекты: такси до объекта за наш счет!</div>
                    </div>
                    <div className="content__headerLink indentB_md" style={{width:"100%"}}>
                        <div class="clrWhite text_smd">При покупке квартиры вы экономите до 40% на ремонт</div>
                    </div>
                    <div className="content__headerLink indentB_md" style={{width:"100%"}}>
                        <div class="clrWhite text_smd">Мы единственные, кто расскажет нюансы, о которых умалчивают застройщики</div>
                    </div>
                    <div className="content__headerLink indentB_md" style={{width:"100%"}}>
                        <div class="clrWhite text_smd">Мы единственные, у кого есть интеллектуальный подбор квартир под ваши параметры</div>
                    </div>
                </div>
            </div>
            <div class="">
                <a class="clrWhite indentB_sm text_slg bold" onClick={(e)=> phoneClickDataLayer(domainSettings.organization.phone)} href={"tel:"+domainSettings.organization.phone}>{domainSettings.organization.phone}</a>
                <div class="text_md button_def clrWhite flex flexCenterY indentB_sm" onClick={(e)=>{e.preventDefault(); setShowModal(true)}}>
                    <FontAwesomeIcon icon={faPhone} /> <span style={{marginLeft:"10px"}}>Заказать обратный звонок</span>
                </div>
            </div>
        </div>
        {showModal?<Modal form={form} setForm={setForm} close={()=>{setShowModal(false)}} raionsPrice={raionsPrice} raionsName={raionsStep} roomsName={roomsStep} sdachaName={sdachaName} celtype={"getCallback"} />:""}
    </React.Fragment>
    )
}