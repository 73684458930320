import React, { useEffect, useState } from "react"
import { GetJKList, phoneClickDataLayer } from "../../utils/send-data";
import { SidePanel } from "../steps/side-panel";
import { BuildingCard } from "./building-card";
import { Modal } from "../modal/modal";

const FinishSteps = ({params, form, setForm, raionsPrice, raionsStep, roomsStep, sdachaName}) => {
    const [stage, setStage] = useState(0)
    const [jkList, setJkList] = useState([])
    const [showModal, setShowModal] = useState(false)
    const nextStep = (event) => {
        event.preventDefault()
        setStage(stage+1)
    }

    useEffect(async () => {
        setTimeout(()=>{
            setShowModal(true)
        }, 1000)
        let jklistPortal = await GetJKList(form)
        setJkList(jklistPortal)
        
    }, [])

    return<React.Fragment>  
        <div class="pAbs zIndex_1 layout layoutSizeMax content over-wrapper js-poll-quiz contentActive">
                <div class="content__container">
                    <SidePanel form={form} setForm={setForm} raionsName={raionsStep} roomsName={roomsStep} celtype={"getForm"} />
                    <div className="listing__layoutAll growOn pRel vue-init vue-completed">
                        <div class="pRel heightAll listing__layoutPart" fragment="9e2f157370">
                            <div class="listing__complete pAbs">
                                <img src="/images/quiz/icon-complete.svg" alt="" width="24" />
                            </div>
                            <div class="listing__container listing__field">
                                {jkList.jks && jkList.jks.map((jk)=>{
                                    return <BuildingCard jk={jk} url={jkList.url} form={form} />
                                })}
                            </div>
                            <div class="listing__gradient"></div>
                            <div class="listing__footerMobile">
                                <a href="https://www.novostroy-m.ru"><img src="/images/quiz/novostroy-logo_gray.svg" alt="" height="28px" /></a>
                                <div class="text_xs">Консультируем бесплатно:<a class="color_blue" onClick={(e)=> phoneClickDataLayer(domainSettings.organization.phone)} href={"tel:"+domainSettings.organization.phone}>{domainSettings.organization.phone}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {showModal?<Modal form={form} setForm={setForm} close={()=>{setShowModal(false)}} raionsPrice={raionsPrice} raionsName={raionsStep} roomsName={roomsStep} sdachaName={sdachaName} celtype={"getForm"} />:""}
    </React.Fragment>
}

export default FinishSteps