import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { startQuizDataLayer } from '../../../utils/send-data';

export const MainCarousel = ({nextStep}) => {
    return (
        <OwlCarousel className='header__slider quiz-carousel owl-carousel' loop margin={100} center autoWidth={true} autoplay={true}>
                        <div class="header__slider-item">
                            <div class="header__slider-icon small">
                                <div class="header__slider-check-wrapper animation-parentCheck">
                                    <div class="header__slider-check-item animation-back">
                                        <div class="header__slider-check-ico animation-check">
                                            <span class="ico">
                                                <svg width="59px" height="38px">
                                                    <use xlinkHref="#quiz-check">
                                                        <symbol viewBox="0 0 59 38" fill="none" xmlns="http://www.w3.org/2000/svg" id="quiz-check">
                                                            <path d="M3 14L24 35L56 3" stroke="white" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"></path>
                                                        </symbol>
                                                    </use>
                                                </svg>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="header__slider-check-item"></div>
                                    <div class="header__slider-check-item"></div>
                                </div>
                            </div>
                            <div class="header__slider-text">
                                Задайте желаемые параметры
                            </div>
                        </div>
                        <div class="header__slider-item">
                            <div class="header__slider-icon">
                                <div class="header__slider-icon-wrapper animation-parent">
                                    <span class="ico">
                                        <svg width="100%" height="100%">
                                            <use xlinkHref="#quiz-brain">
                                                <symbol viewBox="0 0 145 317" fill="none" xmlns="http://www.w3.org/2000/svg" id="quiz-brain"><path d="M63.9198 50.2514V24.4779L107.679 3L142 37.3646V81.1796M63.9198 50.2514L33.8889 67.4337V113.826M63.9198 50.2514L92.2346 71.7293M33.8889 113.826L3 131.008V189.428L33.8889 207.47M33.8889 113.826L63.0617 134.445M33.8889 207.47V249.566L63.9198 267.608M33.8889 207.47L63.9198 189.428M63.9198 267.608V295.959L107.679 314L142 279.635V240.975M63.9198 267.608L94.8086 249.566M142 81.1796L107.679 101.798M142 81.1796V158.5M142 158.5H81.0802M142 158.5V240.975M142 240.975L109.395 219.497" stroke="white" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"></path></symbol>
                                            </use>
                                        </svg>
                                    </span>
                                    <span class="ico transform_180">
                                        <svg width="100%" height="100%">
                                            <use xlinkHref="#quiz-brain">
                                                <symbol viewBox="0 0 145 317" fill="none" xmlns="http://www.w3.org/2000/svg" id="quiz-brain"><path d="M63.9198 50.2514V24.4779L107.679 3L142 37.3646V81.1796M63.9198 50.2514L33.8889 67.4337V113.826M63.9198 50.2514L92.2346 71.7293M33.8889 113.826L3 131.008V189.428L33.8889 207.47M33.8889 113.826L63.0617 134.445M33.8889 207.47V249.566L63.9198 267.608M33.8889 207.47L63.9198 189.428M63.9198 267.608V295.959L107.679 314L142 279.635V240.975M63.9198 267.608L94.8086 249.566M142 81.1796L107.679 101.798M142 81.1796V158.5M142 158.5H81.0802M142 158.5V240.975M142 240.975L109.395 219.497" stroke="white" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"></path></symbol>
                                            </use>
                                        </svg>
                                    </span>
                                </div>
                                <span class="ico search animation-search">
                                    <svg width="50" height="50">
                                        <use xlinkHref="#quiz-search">
                                            <symbol viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg" id="quiz-search"><path fill-rule="evenodd" clip-rule="evenodd" d="M32.9917 10.8076C27.9149 5.7308 19.6837 5.7308 14.6069 10.8076C9.53003 15.8845 9.52986 24.1148 14.6066 29.1915C19.6836 34.2685 27.9151 34.269 32.9917 29.1924C38.0685 24.1156 38.0685 15.8844 32.9917 10.8076ZM10.3643 6.56497C17.7842 -0.854991 29.8144 -0.85499 37.2343 6.56497C44.6543 13.9849 44.6543 26.0151 37.2343 33.435C30.536 40.1334 20.0808 40.7839 12.6518 35.3889L6.12132 41.9194C4.94975 43.091 3.05025 43.091 1.87868 41.9194C0.707107 40.7479 0.707107 38.8484 1.87868 37.6768L8.40916 31.1463C3.01423 23.7173 3.6662 13.263 10.3643 6.56497Z" fill="white"></path></symbol>
                                        </use>
                                    </svg>
                                </span>
                            </div>
                            <div class="header__slider-text">
                                Мы проанализируем ваши параметры
                            </div>
                        </div>
                        <div class="header__slider-item">
                            <div class="header__slider-icon">
                                <div class="header__slider-list-wrapper animation-parentList">
                                    <div class="header__slider-list-item">
                                        <div class="header__slider-list-ico">
                                            <span class="ico">
                                                <svg width="100%" height="100%">
                                                    <use xlinkHref="#quiz-list">
                                                        <symbol viewBox="0 0 269 110" fill="none" xmlns="http://www.w3.org/2000/svg" id="quiz-list">
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M0 3C0 1.34315 1.34315 0 3 0H266C267.657 0 269 1.34315 269 3V107C269 108.657 267.657 110 266 110H3C1.34315 110 0 108.657 0 107V3ZM6 6V104H263V6H6Z" fill="white"></path>
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M22 24C22 20.6863 24.6863 18 28 18H71C74.3137 18 77 20.6863 77 24V85C77 88.3137 74.3137 91 71 91H28C24.6863 91 22 88.3137 22 85V24ZM71 24L28 24V85H71V24Z" fill="white"></path>
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M99 30C99 28.3431 100.343 27 102 27H180C181.657 27 183 28.3431 183 30C183 31.6569 181.657 33 180 33H102C100.343 33 99 31.6569 99 30Z" fill="white"></path>
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M99 43C99 41.3431 100.343 40 102 40H155C156.657 40 158 41.3431 158 43C158 44.6569 156.657 46 155 46H102C100.343 46 99 44.6569 99 43Z" fill="white"></path>
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M99 56C99 54.3431 100.343 53 102 53H212C213.657 53 215 54.3431 215 56C215 57.6569 213.657 59 212 59H102C100.343 59 99 57.6569 99 56Z" fill="white"></path>
                                                        </symbol>
                                                    </use>
                                                </svg>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="header__slider-list-item">
                                        <div class="header__slider-list-ico animation-backList animation-left">
                                            <span class="ico">
                                                <svg width="100%" height="100%">
                                                    <use xlinkHref="#quiz-list">
                                                        <symbol viewBox="0 0 269 110" fill="none" xmlns="http://www.w3.org/2000/svg" id="quiz-list">
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M0 3C0 1.34315 1.34315 0 3 0H266C267.657 0 269 1.34315 269 3V107C269 108.657 267.657 110 266 110H3C1.34315 110 0 108.657 0 107V3ZM6 6V104H263V6H6Z" fill="white"></path>
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M22 24C22 20.6863 24.6863 18 28 18H71C74.3137 18 77 20.6863 77 24V85C77 88.3137 74.3137 91 71 91H28C24.6863 91 22 88.3137 22 85V24ZM71 24L28 24V85H71V24Z" fill="white"></path>
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M99 30C99 28.3431 100.343 27 102 27H180C181.657 27 183 28.3431 183 30C183 31.6569 181.657 33 180 33H102C100.343 33 99 31.6569 99 30Z" fill="white"></path>
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M99 43C99 41.3431 100.343 40 102 40H155C156.657 40 158 41.3431 158 43C158 44.6569 156.657 46 155 46H102C100.343 46 99 44.6569 99 43Z" fill="white"></path>
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M99 56C99 54.3431 100.343 53 102 53H212C213.657 53 215 54.3431 215 56C215 57.6569 213.657 59 212 59H102C100.343 59 99 57.6569 99 56Z" fill="white"></path>
                                                        </symbol>
                                                    </use>
                                                </svg>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="header__slider-list-item">
                                        <div class="header__slider-list-ico">
                                            <span class="ico">
                                                <svg width="100%" height="100%">
                                                    <use xlinkHref="#quiz-list">
                                                        <symbol viewBox="0 0 269 110" fill="none" xmlns="http://www.w3.org/2000/svg" id="quiz-list">
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M0 3C0 1.34315 1.34315 0 3 0H266C267.657 0 269 1.34315 269 3V107C269 108.657 267.657 110 266 110H3C1.34315 110 0 108.657 0 107V3ZM6 6V104H263V6H6Z" fill="white"></path>
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M22 24C22 20.6863 24.6863 18 28 18H71C74.3137 18 77 20.6863 77 24V85C77 88.3137 74.3137 91 71 91H28C24.6863 91 22 88.3137 22 85V24ZM71 24L28 24V85H71V24Z" fill="white"></path>
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M99 30C99 28.3431 100.343 27 102 27H180C181.657 27 183 28.3431 183 30C183 31.6569 181.657 33 180 33H102C100.343 33 99 31.6569 99 30Z" fill="white"></path>
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M99 43C99 41.3431 100.343 40 102 40H155C156.657 40 158 41.3431 158 43C158 44.6569 156.657 46 155 46H102C100.343 46 99 44.6569 99 43Z" fill="white"></path>
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M99 56C99 54.3431 100.343 53 102 53H212C213.657 53 215 54.3431 215 56C215 57.6569 213.657 59 212 59H102C100.343 59 99 57.6569 99 56Z" fill="white"></path>
                                                        </symbol>
                                                    </use>
                                                </svg>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="header__slider-text">
                                Отправим вам список вариантов
                            </div>
                        </div>
                        <div class="header__slider-item">
                            <div class="header__slider-text">
                                <a onClick={(e)=>{e.preventDefault(); startQuizDataLayer(); nextStep()}} class="button_def button__size button__type_3 text_smd medium" href="javascript:void(0)" onclick="$('.js-start-quiz').css('display', 'none');$('.js-poll-quiz').addClass('contentActive')">
                                    Подобрать квартиру
                                </a>
                            </div>
                        </div>
                    </OwlCarousel>
    )
}