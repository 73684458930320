import { useEffect, useState } from "react"

const BtnComponent = ({params, clickFunct, text, analog}) => {
    /*const defaultStyle = {background:`#${params.main_color}`}
    const [styleC, setStyle] = useState({background:`#${params.main_color}`})

    useEffect(()=>{
        setStyle({background:`#${params.main_color}`})
    },[params])
    
    const hoveredStyle = {background:`#${params.main_color}`, color: `#${params.secondary_color}`, borderColor:`#${params.secondary_color}`}*/
    if (analog) {
        return <button class="button_def button__size button__type_2" onClick={clickFunct}>{text}</button>
    }
    return <button class="button_def button__size button__type_1 text_md medium " onClick={clickFunct}>{text}</button>
}

export default BtnComponent