import { clickLinkDataLayer, generateParamsForUrl } from "../../utils/send-data"

export const BuildingCard = ({jk, url, form}) => {
  return ( <a href={`${url}${jk.code}/${generateParamsForUrl(form)}`}  target="_blank" class="card__wrapper clrBlack hoverBox" fragment="fd45eb8d17">
                                <div class="card__imageBox">
                                    <img src={jk.photo} alt="" class="card__image" />
                                </div>
                            <div class="card__title text_smd medium hover_blue">
                               {jk.name}
                            </div>
                            <div class="card__textBox">
                                <div>{domainSettings.city.Name}</div>
                                <div class="card__metroBox text_sm">
                                {/*<div class="card__metroDot" style={{backgroundColor: "rgb(230, 51, 42)"}}></div>
                                <div class="card__metroName text_crop">Академическая</div>*/}
                            </div>
                        </div>
                            <div class="card__price bold text_md">
                                от {jk.priceOt?.toLocaleString()} руб.
                            </div>
                            {jk.flats_cnt?<div class="card__count text_md">{jk.flats_cnt} квартир в продаже</div>:""}
                    </a>
  )
}